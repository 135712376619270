import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  array,
  arrayOf,
  bool,
  func,
  node,
  number,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import loadable from '@loadable/component';
import classNames from 'classnames';
import omit from 'lodash/omit';

import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import {
  propTypes,
  LISTING_STATE_CLOSED,
  LINE_ITEM_NIGHT,
  LINE_ITEM_DAY,
  LINE_ITEM_ITEM,
  LINE_ITEM_HOUR,
} from '../../util/types';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import { userDisplayNameAsString } from '../../util/data';
import {
  INQUIRY_PROCESS_NAME,
  getSupportedProcessesInfo,
  isBookingProcess,
  isPurchaseProcess,
  resolveLatestProcessName,
} from '../../transactions/transaction';

import {
  ModalInMobile,
  PrimaryButton,
  AvatarSmall,
  H1,
  H2,
  SecondaryButton,
} from '../../components';
import { types as sdkTypes } from '../../util/sdkLoader';
import { brandNameConverter } from '../../util/string';
import { displayExtraInfo } from '../../config/configLayout';
import { formatSharetribeField } from '../../util/string';

import css from './OrderPanel.module.css';
import { BOOKING_TYPES } from '../../util/enums';

const BookingTimeForm = loadable(() =>
  import(/* webpackChunkName: "BookingTimeForm" */ './BookingTimeForm/BookingTimeForm')
);
const BookingDatesForm = loadable(() =>
  import(/* webpackChunkName: "BookingDatesForm" */ './BookingDatesForm/BookingDatesForm')
);
const InquiryWithoutPaymentForm = loadable(() =>
  import(
    /* webpackChunkName: "InquiryWithoutPaymentForm" */ './InquiryWithoutPaymentForm/InquiryWithoutPaymentForm'
  )
);
const ProductOrderForm = loadable(() =>
  import(/* webpackChunkName: "ProductOrderForm" */ './ProductOrderForm/ProductOrderForm')
);

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;
const TODAY = new Date();

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openOrderModal = (isOwnListing, isClosed, history, location, type) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), orderOpen: true, type })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeOrderModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = parse(search);
  const updatedSearchParams = omit(searchParams, ['orderOpen', 'type']);
  const searchString = `?${stringify(updatedSearchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};
const renderPurchaseButton = (
  handleSubmit,
  isOwnListing,
  isClosed,
  showInquiryForm,
  onSubmit,
  history,
  location,
  isOutOfStock,
  isBooking,
  isPurchase
) => {
  const handleClick = handleSubmit(
    isOwnListing,
    isClosed,
    showInquiryForm,
    onSubmit,
    history,
    location,
    BOOKING_TYPES.PURCHASE
  );

  const getMessageId = () => {
    if (isBooking) {
      return 'OrderPanel.ctaButtonMessageBooking';
    }
    if (isOutOfStock) {
      return 'OrderPanel.ctaButtonMessageNoStock';
    }
    if (isPurchase) {
      return 'OrderPanel.ctaButtonMessagePurchase';
    }
    return 'OrderPanel.ctaButtonMessageInquiry';
  };

  return (
    <PrimaryButton onClick={handleClick} disabled={isOutOfStock}>
      <FormattedMessage id={getMessageId()} />
    </PrimaryButton>
  );
};

const renderOfferButton = (
  handleSubmit,
  isOwnListing,
  isClosed,
  showInquiryForm,
  onSubmit,
  history,
  location,
  isOutOfStock
) => {
  const handleClick = handleSubmit(
    isOwnListing,
    isClosed,
    showInquiryForm,
    onSubmit,
    history,
    location,
    BOOKING_TYPES.OFFER
  );

  return (
    <PrimaryButton onClick={handleClick} disabled={isOutOfStock}>
      <FormattedMessage id="OrderPanel.makeAnOffer" />
    </PrimaryButton>
  );
};

const handleSubmit = (
  isOwnListing,
  isClosed,
  isInquiryWithoutPayment,
  onSubmit,
  history,
  location,
  type
) => {
  // TODO: currently, inquiry-process does not have any form to ask more order data.
  // We can submit without opening any inquiry/order modal.
  return isInquiryWithoutPayment
    ? () => onSubmit({})
    : () => openOrderModal(isOwnListing, isClosed, history, location, type);
};

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

const PriceMaybe = props => {
  const { price, publicData, validListingTypes, intl } = props;
  const { listingType, unitType, originalGtPrice } = publicData || {};
  const { Money } = sdkTypes;

  let originalPriceFormatted = null;
  if (typeof originalGtPrice === 'number') {
    const originalPriceMoney = new Money(originalGtPrice, price.currency);
    originalPriceFormatted = formatMoney(intl, originalPriceMoney);
  }

  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice || !price) {
    return null;
  }

  return (
    <div className={css.priceContainer}>
      <p className={css.price}>{formatMoney(intl, price)}</p>
      {originalPriceFormatted && (
        <div className={css.priceContainer}>
          <p className={css.originalPriceInfoSeperator}>|</p>
          <p className={css.originalPriceInfo}>
            <FormattedMessage id="OrderPanel.originalPrice" />
          </p>
          <p className={css.originalPrice}>{originalPriceFormatted}</p>
        </div>
      )}

      <div className={css.perUnit}>
        <FormattedMessage id="OrderPanel.perUnit" values={{ unitType }} />
      </div>
    </div>
  );
};

const OrderPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    validListingTypes,
    lineItemUnitType: lineItemUnitTypeMaybe,
    isOwnListing,
    onSubmit,
    title,
    titleDesktop,
    author,
    authorLink,
    onManageDisableScrolling,
    onFetchTimeSlots,
    monthlyTimeSlots,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    onContactUser,
    lineItems,
    marketplaceCurrency,
    dayCountAvailableForBooking,
    marketplaceName,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    variantListings,
    selectedVariant,
    setSelectedVariant,
    isMobileLayout,
    onSendInquiry,
    currentUser,
    sendInquiryInProgress,
    onToggleOffersModal = () => {},
  } = props;

  const publicData = listing?.attributes?.publicData || {};
  const brandName = publicData.brandChild;
  const colour = publicData.colour;
  const { unitType, transactionProcessAlias = '', originalGtPrice: gtPrice } = publicData || {};
  const processName = resolveLatestProcessName(transactionProcessAlias.split('/')[0]);
  const lineItemUnitType = lineItemUnitTypeMaybe || `line-item/${unitType}`;
  const { Money } = sdkTypes;
  const originalGtPrice = selectedVariant?.originalGtPrice || gtPrice;

  const price = selectedVariant?.price || listing?.attributes?.price;
  const isPaymentProcess = processName !== INQUIRY_PROCESS_NAME;

  let originalPriceFormatted = null;
  if (typeof originalGtPrice === 'number') {
    const originalPriceMoney = new Money(originalGtPrice, price.currency);
    originalPriceFormatted = formatMoney(intl, originalPriceMoney);
  }

  const showPriceMissing = isPaymentProcess && !price;
  const PriceMissing = () => {
    return (
      <p className={css.error}>
        <FormattedMessage id="OrderPanel.listingPriceMissing" />
      </p>
    );
  };
  const showInvalidCurrency = isPaymentProcess && price?.currency !== marketplaceCurrency;
  const InvalidCurrency = () => {
    return (
      <p className={css.error}>
        <FormattedMessage id="OrderPanel.listingCurrencyInvalid" />
      </p>
    );
  };

  const timeZone = listing?.attributes?.availabilityPlan?.timezone;
  const isClosed = listing?.attributes?.state === LISTING_STATE_CLOSED;

  const isBooking = isBookingProcess(processName);
  const shouldHaveBookingTime = isBooking && [LINE_ITEM_HOUR].includes(lineItemUnitType);
  const showBookingTimeForm = shouldHaveBookingTime && !isClosed && timeZone;

  const shouldHaveBookingDates =
    isBooking && [LINE_ITEM_DAY, LINE_ITEM_NIGHT].includes(lineItemUnitType);
  const showBookingDatesForm = shouldHaveBookingDates && !isClosed && timeZone;

  // The listing resource has a relationship: `currentStock`,
  // which you should include when making API calls.
  const isPurchase = isPurchaseProcess(processName);
  const currentStock = listing.currentStock?.attributes?.quantity;
  const isOutOfStock = isPurchase && lineItemUnitType === LINE_ITEM_ITEM && currentStock === 0;

  // Show form only when stock is fully loaded. This avoids "Out of stock" UI by
  // default before all data has been downloaded.
  const showProductOrderForm = isPurchase && typeof currentStock === 'number';

  const showInquiryForm = processName === INQUIRY_PROCESS_NAME;

  const supportedProcessesInfo = getSupportedProcessesInfo();
  const isKnownProcess = supportedProcessesInfo.map(info => info.name).includes(processName);

  const { pickupEnabled, shippingEnabled } = listing?.attributes?.publicData || {};

  const showClosedListingHelpText = listing.id && isClosed;
  const { formattedPrice, priceTitle } = priceData(price, marketplaceCurrency, intl);
  const isOrderOpen = !!parse(location.search).orderOpen;
  const isOffersModalOpen = parse(location.search).type === BOOKING_TYPES.OFFER;

  const subTitleText = showClosedListingHelpText
    ? intl.formatMessage({ id: 'OrderPanel.subTitleClosedListing' })
    : null;

  const authorDisplayName = userDisplayNameAsString(author, '');

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.orderTitle);
  const listingCountryCode = listing?.attributes?.publicData?.countryCode;

  return (
    <div className={classNames(css.fixedPosition, rootClassName)}>
      <div className={css.brandName}>
        {displayExtraInfo.displaySubBrands ? brandNameConverter(brandName) : null}
      </div>

      <div className={css.orderHeading}>
        {titleDesktop ? titleDesktop : <H2 className={titleClasses}>{title}</H2>}
        {subTitleText ? <div className={css.orderHelp}>{subTitleText}</div> : null}
      </div>

      <PriceMaybe
        price={price}
        publicData={publicData}
        validListingTypes={validListingTypes}
        intl={intl}
      />
      <div className={css.greySeperatorLine}></div>
      <div className={css.authorDescription}>
        <FormattedMessage id="OrderPanel.authorDescription" values={{ name: authorDisplayName }} />
      </div>
      <div className={css.author}>
        <span className={css.providerNamePlain}>
          <FormattedMessage id="OrderPanel.author" values={{ name: authorDisplayName }} />
        </span>
      </div>
      <div className={css.padding}></div>
      <div className={css.authorDescription}>
        <FormattedMessage id="OrderPanel.colourDescription" />
      </div>
      <div className={css.author}>
        <span className={css.providerNamePlain}>
          <span>{formatSharetribeField(colour)}</span>
        </span>
      </div>

      {showPriceMissing ? (
        <PriceMissing />
      ) : showInvalidCurrency ? (
        <InvalidCurrency />
      ) : showBookingTimeForm ? (
        <BookingTimeForm
          className={css.bookingForm}
          formId="OrderPanelBookingTimeForm"
          lineItemUnitType={lineItemUnitType}
          onSubmit={onSubmit}
          price={price}
          marketplaceCurrency={marketplaceCurrency}
          dayCountAvailableForBooking={dayCountAvailableForBooking}
          listingId={listing.id}
          isOwnListing={isOwnListing}
          monthlyTimeSlots={monthlyTimeSlots}
          onFetchTimeSlots={onFetchTimeSlots}
          startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
          endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
          timeZone={timeZone}
          marketplaceName={marketplaceName}
          onFetchTransactionLineItems={onFetchTransactionLineItems}
          lineItems={lineItems}
          fetchLineItemsInProgress={fetchLineItemsInProgress}
          fetchLineItemsError={fetchLineItemsError}
        />
      ) : showBookingDatesForm ? (
        <BookingDatesForm
          className={css.bookingForm}
          formId="OrderPanelBookingDatesForm"
          lineItemUnitType={lineItemUnitType}
          onSubmit={onSubmit}
          price={price}
          marketplaceCurrency={marketplaceCurrency}
          dayCountAvailableForBooking={dayCountAvailableForBooking}
          listingId={listing.id}
          isOwnListing={isOwnListing}
          monthlyTimeSlots={monthlyTimeSlots}
          onFetchTimeSlots={onFetchTimeSlots}
          timeZone={timeZone}
          marketplaceName={marketplaceName}
          onFetchTransactionLineItems={onFetchTransactionLineItems}
          lineItems={lineItems}
          fetchLineItemsInProgress={fetchLineItemsInProgress}
          fetchLineItemsError={fetchLineItemsError}
        />
      ) : showProductOrderForm ? (
        <ProductOrderForm
          formId="OrderPanelProductOrderForm"
          onSubmit={onSubmit}
          price={price}
          marketplaceCurrency={marketplaceCurrency}
          currentStock={currentStock}
          pickupEnabled={pickupEnabled}
          currentUser={currentUser}
          history={history}
          sendInquiryInProgress={sendInquiryInProgress}
          shippingEnabled={shippingEnabled}
          listingId={listing.id}
          isOwnListing={isOwnListing}
          marketplaceName={marketplaceName}
          variantListings={variantListings}
          isOffersModalOpen={isOffersModalOpen}
          isMobileLayout={isMobileLayout}
          selectedVariant={selectedVariant}
          setSelectedVariant={setSelectedVariant}
          onToggleOffersModal={onToggleOffersModal}
          onSendInquiry={onSendInquiry}
          onFetchTransactionLineItems={onFetchTransactionLineItems}
          onCloseBookingModal={() => closeOrderModal(history, location)}
          onContactUser={onContactUser}
          lineItems={lineItems}
          fetchLineItemsInProgress={fetchLineItemsInProgress}
          fetchLineItemsError={fetchLineItemsError}
          listingCountryCode={listingCountryCode}
        />
      ) : showInquiryForm ? (
        <InquiryWithoutPaymentForm formId="OrderPanelInquiryForm" onSubmit={onSubmit} />
      ) : !isKnownProcess ? (
        <p className={css.errorSidebar}>
          <FormattedMessage id="OrderPanel.unknownTransactionProcess" />
        </p>
      ) : null}
      <div className={css.openOrderForm}>
        <div className={css.priceContainerInCTA}>
          {originalPriceFormatted && (
            <div className={css.originalPriceValue}>{originalPriceFormatted}</div>
          )}
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>
          <div className={css.perUnitInCTA}>
            <FormattedMessage id="OrderPanel.perUnit" values={{ unitType }} />
          </div>
        </div>
        {isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="OrderPanel.closedListingButtonText" />
          </div>
        ) : (
          <>
            {renderPurchaseButton(
              handleSubmit,
              isOwnListing,
              isClosed,
              showInquiryForm,
              onSubmit,
              history,
              location,
              isOutOfStock,
              isBooking,
              isPurchase
            )}
            {renderOfferButton(
              handleSubmit,
              isOwnListing,
              isClosed,
              showInquiryForm,
              onSubmit,
              history,
              location,
              isOutOfStock
            )}
          </>
        )}
      </div>
    </div>
  );
};

OrderPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  authorLink: null,
  titleDesktop: null,
  subTitle: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

OrderPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  validListingTypes: arrayOf(
    shape({
      listingType: string.isRequired,
      transactionType: shape({
        process: string.isRequired,
        alias: string.isRequired,
        unitType: string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  isOwnListing: bool,
  author: oneOfType([propTypes.user, propTypes.currentUser]).isRequired,
  authorLink: node,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  titleDesktop: node,
  subTitle: oneOfType([node, string]),
  onManageDisableScrolling: func.isRequired,

  onFetchTimeSlots: func.isRequired,
  monthlyTimeSlots: object,
  onFetchTransactionLineItems: func.isRequired,
  onContactUser: func,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  marketplaceCurrency: string.isRequired,
  dayCountAvailableForBooking: number.isRequired,
  marketplaceName: string.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(withRouter, injectIntl)(OrderPanel);
