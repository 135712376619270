export const OFFER_CONSTANTS = {
  INITIAL: 'initial',
  OFFERED: 'offered',
  ACCEPTED: 'accepted',
  DECLINED: 'declined',
  COUNTER: 'counter',
  COUNTER_BY_CUSTOMER: 'counterByCustomer',
  PENDING: 'pending',
  ACCEPT: 'accept',
  ACCEPT_AFTER_COUNTER_OFFER: 'acceptAfterCounterOffer',
  ACCEPT_AFTER_COUNTER_OFFER_BY_PROVIDER: 'acceptAfterCounterOfferByProvider',
  DECLINE_AFTER_COUNTER_OFFER: 'declineAfterCounterOffer',
  DECLINE_AFTER_COUNTER_OFFER_BY_PROVIDER: 'declineAfterCounterOfferByProvider',
};

export const OFFER_STATE_COLORS = {
  OFFERED: '#f77f00',
  ACCEPTED: '#1e4c2f',
  DECLINED: '#59766c',
  EXPIRED: '#ED1B24',
};

export const LISTING_MIN_PRICE = 0;
export const BASE_CURRENCY = 'EUR';
export const BASE_CURRENCY_SYMBOL = '€';
export const OPEN_OFFER_MODAL = '#openOfferModal';

export const MOBILE_MODAL_BREAKPOINT = 768;

export const BOOKING_TYPES = {
  OFFER: 'offer',
  PURCHASE: 'purchase',
};

export const transactionTransitions = {
  TRANSITION_REQUEST_PAYMENT_KLARNA: 'transition/request-payment-klarna-or-sepa',
  TRANSITION_REQUEST_PAYMENT_AFTER_INQUIRY_KLARNA_OR_SEPA:
    'transition/request-payment-after-inquiry-klarna-or-sepa',
  TRANSITION_CONFIRM_PAYMENT_KLARNA: 'transition/confirm-payment-klarna-or-sepa',
};
export const stripeCountries = [
  //We use names keeping in mind if website will be translated to other languages
  {
    code: 'AT',
    names: {
      en: 'Austria',
    },
  },
  // {
  //   code: 'BE',
  //   names: {
  //     en: 'Belgium',
  //   },
  // },
  {
    code: 'DE',
    names: {
      en: 'Germany',
    },
  },
  // {
  //   code: 'ES',
  //   names: {
  //     en: 'Spain',
  //   },
  // },
  // {
  //   code: 'IT',
  //   names: {
  //     en: 'Italy',
  //   },
  // },
  // {
  //   code: 'NL',
  //   names: {
  //     en: 'Netherlands',
  //   },
  // },
];
export const PAYMENT_PROVIDERS = {
  CARD: 'card',
  KLARNA: 'klarna',
  SEPA: 'sepa',
};
