// Convert kebab-case to camelCase: my-page-asset > myPageAsset
export const camelize = s => s.replace(/-(.)/g, l => l[1].toUpperCase());

export const replaceUnderscoresWithHyphens = inputString => {
  return inputString.replace(/_/g, '-');
};

// Convert brandChild property to visual friendly string
export const brandNameConverter = brandChild => {
  const brandId = process.env.REACT_APP_BRAND_NAME;

  if (!brandChild) return '';

  if (brandId === 'gerry_weber') {
    const mapping = {
      gerryweber: 'GERRY WEBER',
      taifun: 'TAIFUN',
      samoon: 'SAMOON',
    };
    return mapping[brandChild.toLowerCase()] || brandChild;
  }

  return '';
};

export const formatSharetribeField = colour => {
  return colour
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};
